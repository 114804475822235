
.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-dark {
	background: #161C24;
	border-right: 1px solid #343B44;
}


.dark-layout .main-menu-content .navigation-main{
	background: #0d0e14 !important;
}
.dark-layout .main-menu-content .navigation-main .active svg {
    color: #ffffff;
}

.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-dark {
	background: #161C24;
	border-right: 1px solid #343B44;
}

.main-menu .navbar-header{
	height: 5.5rem;
}

.main-menu .navigation > li.active > a {
	box-shadow: none !important;
}

.dark-layout .modal .modal-header .btn-close {
	background-color: transparent !important;
	box-shadow: none !important;
	margin-top: 5px;
	margin-right: 10px;
}


.modal .modal-header .btn-close {
	background-color: transparent !important;
	box-shadow: none !important;
	margin-top: 5px;
	margin-right: 10px;
}

span.build-version {
	font-size: 11px;
	color: #767675;
}


.metamask-login-btn {
	display: flex;
	border-radius: 50px;
	border: 1px solid #6e6e6e;
	padding: 7px 30px;
	margin-bottom: 30px;
	justify-content: center;
	align-items: center;
}


.metamask-login-btn .logo {
	width: 25px;
	margin-right: 5px;

}

.walletconnect-login-btn {
	display: flex;
	border-radius: 50px;
	border: 1px solid #6e6e6e;
	padding: 10px 30px;
	margin-bottom: 10px;
	justify-content: center;
	align-items: center;
}

.walletconnect-login-btn:hover {
	cursor: pointer;
}

.metamask-login-btn:hover {
	cursor: pointer;
}


.walletconnect-login-btn .logo {
	width: 25px;
	margin-right: 5px;
}

.walletconnect-logo{
	width: 25px;
}

.dark-layout .header-navbar {
	background-color: #12141D !important;
}

.buy-token-widget-container {
	display: flex;
	justify-content: center;
}

.login-model-body.modal-body {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dark-layout .data-info-widget.card-body {
	color: #ffffff;
	background: #2a2c34;
}
.vote-info-widget.card-body {
	padding: 1.5rem 1rem 0rem 1rem;
	cursor: pointer;
}
.dark-layout .card-body {
	color: #ffffff;
	background: #2a2c34;
}

.data-info-widget-title{
	font-size: 20px;
	margin-bottom: 10px;
}
.data-info-row {
	display: flex;
	justify-content: space-between;
}

.data-info-value {
	font-weight: 700;
}

.data-info-row {
	margin-bottom: 5px;
}
// .wallet-address.mobile {
// 	font-weight: 800;
// 	border-bottom: 1px dotted rgb(255, 255, 255);
//   }

.data-info-progress-tracker-title {
	// margin: 10px 0;
	text-align: start;
	font-size: 16px;
	font-weight: 800;
}

@media only screen and (max-width: 375px) {
  .data-info-row  {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1024px) {
	.data-info-progress-tracker  {
	  border-top: 1px dotted rgb(255, 255, 255);
	}
	.user-dividend-data-container {
		margin-bottom: 30px;
	}
	.total-user-rewards {
		background-color: #161c24;
		padding-top: 20px;
		border-radius: 10px;
	}
  }

@media only screen and (min-width: 425px) {
  .data-info-widget.card-body {
    min-height: 340px !important;
	}
}

.user-dividend-data-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.user-divided-data-title {
	font-weight: 800;
	font-size: 14px;
}

.user-divided-data-value {
	font-size: 20px;
	font-weight: 800;
}

.claim-your-token-title {
	text-align: center;
	font-size: 20px;
	font-weight: 800;
}
.claim-your-token-value {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.dark-layout .rdt_TableHeadRow{
	background: #191e29;
	color: #ffffff;
	font-weight: 700;
	min-height: 35px;
}

.dark-layout  .rdt_TableRow{
	background: #2a2c34;
	min-height: 40px;
}

.rdt_TableHeadRow{
	min-height: 35px !important;
}

.rdt_TableRow{
	min-height: 40px !important;
}

.dark-layout .rdt_Pagination{
	background: #2a2c34;
	color: #ffffff;
}

.dark-layout #pagination-first-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-first-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-last-page {
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-last-page:disabled {
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-previous-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-next-page:disabled{
	cursor: unset;
	color: #949494 !important;
	fill: #9494947c !important;
}

.dark-layout #pagination-previous-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout #pagination-next-page{
	color: #ffffff !important;
	fill: #ffffff !important;
}

.dark-layout .dt-tnx-hash a{
	color: #ffffff !important;
}

.dark-layout .dt-from a{
	color: #ffffff !important;
}

.dark-layout .dt-to a{
	color: #ffffff !important;
}

.main-menu.menu-dark .navigation li a > * {
    transition: transform 0.25s ease;
    color: #ffffff;
	font-weight: 600;
}
.dark-layout .main-menu .main-menu-content {
    background: #0d0e14;
}
.dark-layout .main-menu .navbar-header {
    background: #0d0e14 !important;
}
.dark-layout .content.app-content {
    background: #12141D;
}
.dark-layout .vertical-layout.vertical-menu-modern.menu-expanded .footer {
    background: #12141D;
}
.dark-layout input.form-control:not(:focus), [dir] .dark-layout select.form-select:not(:focus), [dir] .dark-layout textarea.form-control:not(:focus) .dark-layout textarea.form-control:focus{
	background: #2a2c34;
}
.dark-layout .input-group .input-group-text {
    background-color: #2a2c34;
    border-color: #88919d;
}
.dark-layout input.form-control:not(:focus), [dir] .dark-layout select.form-select:not(:focus), [dir] .dark-layout textarea.form-control:not(:focus) {
    border-color: #88919d !important;
}
.dark-layout .voted-link {
	
}
.card-header:first-child {
	border-bottom: #90be21 solid 1px;
    padding: 1px;
}
.dark-layout .vote-info {
	border: solid 2px rgb(170, 170, 170) !important;
}
.poll-create-modal {
	width: 700px;
}
.rewards-cal-price-in-usd {
	font-weight: 700;
	font-size: 12px;
	color: #79a01f;
}

.rewards-cal-main-value {
	font-size: 22px;
	padding-bottom: 5px;
}

@media only screen and (max-width: 425px) {
  .token-input-icon {
    display: none;
	}
}